<script setup lang="ts">
import type { Amount } from '@/env'
import { onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { leftSettlementPeriod, USD } from '@/utils'
import { useSearchStore } from '@/stores'

import ActionCard from '@/components/ActionCard.vue'

interface Props {
  price: number
  prices: Amount[]
  pricesLoading: boolean
  date: number
  dates: { id: string; value: Amount[] }[]
  currentDates: Amount[]
  datesLoading: boolean
  disabled: boolean
  showBack: boolean
  value: number
  index: number
  fetch: (flag: boolean) => Promise<void>
  fetchDates: (price: number) => Promise<void>
}

const props = withDefaults(defineProps<Props>(), {
  price: 0,
  prices: () => [],
})

const emit = defineEmits([
  'update:price',
  'update:date',
  'update:disabled',
  'next',
  'prev',
])

const search = useSearchStore()

const btnCornerBack = ref()

const updateDate = (val: number) => {
  emit('update:date', val)
}
const updatePrice = (val: number) => {
  emit('update:price', val)
}
const cornerBackTransition = () => {
  emit('update:disabled', false)
}

// 监听第一个卡片 price 变动，请求新的 expiryDates
watch(
  () => props.price,
  (price) => {
    if (price) {
      props.fetchDates(price)
    }
  }
)

const loadData = (flag = false) => props.fetch(flag)

search.$subscribe(() => loadData(true))

onMounted(() => {
  btnCornerBack.value?.addEventListener(
    'transitionend',
    cornerBackTransition,
    false
  )
  loadData()
})
onBeforeUnmount(() => {
  btnCornerBack.value?.removeEventListener(
    'transitionend',
    cornerBackTransition
  )
})
</script>

<template>
  <div class="flex flex-col items-center">
    <svg
      width="470"
      height="493"
      viewBox="0 0 470 493"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5390_41768)">
        <path
          d="M117.956 175.34C118.845 155.018 135.579 139 155.92 139H314.08C334.421 139 351.155 155.018 352.044 175.34L360.266 363.34C361.211 384.957 343.94 403 322.302 403H147.698C126.06 403 108.789 384.957 109.734 363.34L117.956 175.34Z"
          fill="#121218"
          fill-opacity="0.26"
          shape-rendering="geometricPrecision"
        />
        <path
          d="M118.955 175.383C119.821 155.596 136.114 140 155.92 140H314.08C333.886 140 350.179 155.596 351.045 175.383L359.266 363.383C360.187 384.432 343.371 402 322.302 402H147.698C126.629 402 109.813 384.432 110.733 363.383L118.955 175.383Z"
          stroke="url(#paint0_linear_5390_41768)"
          stroke-width="2"
          shape-rendering="geometricPrecision"
        />
      </g>

      <defs>
        <filter
          id="filter0_d_5390_41768"
          x="29.6973"
          y="69"
          width="410.605"
          height="424"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="10" />
          <feGaussianBlur stdDeviation="40" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.309804 0 0 0 0 0.286274 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5390_41768"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5390_41768"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_5390_41768"
          x="0.951172"
          y="34"
          width="468.098"
          height="443"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="14" />
          <feGaussianBlur stdDeviation="40" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.309804 0 0 0 0 0.286274 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5390_41768"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5390_41768"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_f_5390_41768"
          x="8"
          y="0"
          width="449"
          height="454"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="50"
            result="effect1_foregroundBlur_5390_41768"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5390_41768"
          x1="235"
          y1="139"
          x2="235"
          y2="403"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.576654" stop-color="#EF7E0E" />
          <stop offset="1" stop-color="#EF7E0E" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5390_41768"
          x1="235"
          y1="100"
          x2="235"
          y2="383"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.576654" stop-color="#EF7E0E" />
          <stop offset="1" stop-color="#EF7E0E" stop-opacity="0.3" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_5390_41768"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(232.5 194.689) rotate(90) scale(159.311 156.175)"
        >
          <stop offset="0.00256814" stop-color="#FF6F61" />
          <stop
            offset="0.723958"
            stop-color="#FF6F61"
            stop-opacity="0.496066"
          />
          <stop offset="1" stop-color="#FF6F61" stop-opacity="0" />
        </radialGradient>
      </defs>
    </svg>

    <transition name="slide-fade-in">
      <keep-alive>
        <action-card
          v-if="showBack"
          type="sp"
          :value="date"
          @update:value="updateDate"
          :loading="datesLoading"
          :cached-list="currentDates"
          :format="(value) => leftSettlementPeriod(value.expiration_date)"
          :get="(value) => new Date(value.expiration_date).getTime()"
          title="WAIT"
        />
      </keep-alive>
    </transition>
    <transition name="slide-fade-out">
      <keep-alive>
        <action-card
          v-if="!showBack"
          type="sp"
          :order="true"
          :value="price"
          @update:value="updatePrice"
          :loading="pricesLoading"
          :cached-list="prices"
          :format="(value) => USD(value).format()"
          :get="(value) => value"
          :title="`SELL ${search?.baseCurrency} WHEN`"
        />
      </keep-alive>
    </transition>

    <div class="h-16 w-auto flex flex-row">
      <button
        :disabled="disabled"
        ref="btnCornerBack"
        class="h-16 w-16 box-border rounded-3 border-width-1 border-neutral-500 border-opacity-40 flex items-center justify-center text-neutral-500 btn btn-corner-back"
        :style="{
          opacity: Number(showBack),
          transform: `translate3d(${showBack ? -8 : 64 / 2}px, 0, 0)`,
        }"
        un-hover="border-white text-white"
        @click="$emit('prev')"
      >
        <SvgIcon name="corner-back" width="22" height="22" />
      </button>
      <button
        :disabled="disabled"
        class="w-46.75 h-16 bg-white rounded-3 text-base font-800 uppercase btn select-none"
        :style="{
          transform: `translate3d(${showBack ? 8 : -64 / 2}px, 0, 0)`,
        }"
        un-hover="btn-hover"
        @click="$emit('next')"
      >
        Next
      </button>
    </div>

    <div
      class="w-18 h-1 rounded-2 overflow-hidden appearance-none my-10.5 relative progress"
      :style="`--value: ${value}%`"
    />
  </div>
</template>
