<template>
  <div
    id="wallets"
    class="px-5 pt-10 pb-6 box-border w-103 bg-neutral-1001 rounded-2.5xl absolute inset-border select-none modal-container"
  >
    <h3 class="font-800 text-white text-2xl mb-11.25 mx-2 flex items-center">
      <SvgIcon
        name="left-arrow"
        class="mr-4.5 cursor-pointer text-neutral-455 btn-back"
        un-hover="text-white"
        width="18"
        height="18"
        @click="$emit('prev')"
      />
      Connect Wallet
    </h3>
    <SvgIcon
      name="close"
      class="absolute top-10 right-7 cursor-pointer text-neutral-455 btn-close"
      un-hover="text-white"
      width="18"
      height="18"
      @click="$emit('close')"
    />
    <a
      v-for="(item, i) in menu.children"
      :key="`${network}-${i}`"
      class="flex items-center justify-between h-18 rounded-3 box-border bg-dark-401 mt-4.5 px-6 font-500 text-white text-lg"
      :class="{ selected: wallet === i, [`${item.class}`]: 1 }"
      @click="select(item, network, i)"
    >
      <SvgIcon :name="item.icon" width="40" height="40" class="mr-4" />
      <span class="flex-1 text-left">{{ item.label }}</span>
      <SmallLoading class="icon-loading text-white" />
    </a>
    <a
      v-if="menu.link"
      :href="menu.link"
      target="_blank"
      rel="noopener"
      role="button"
      class="flex items-center justify-between h-12 rounded-3 box-border bg-dark-401 mt-6 px-7.5 font-400 text-white text-base opacity-50 link"
    >
      <span class="flex-1 text-left">Learn more about wallets</span>
      <SvgIcon name="top-right-arrow" width="18" height="18" />
    </a>
  </div>
</template>

<style>
#wallets a {
  cursor: pointer;
  border: 1.3px solid transparent;
  transition: border-color 0.2 ease;
}
#wallets a:hover,
#wallets a.selected {
  border-color: #797d8f;
}

/* #wallets a.metamask:hover,
#wallets a.metamask.selected {
  border-color: #f6851b;
}
#wallets a.wallet:hover,
#wallets a.wallet.selected {
  border-color: #5697f5;
}
#wallets a.coinbase:hover,
#wallets a.coinbase.selected {
  border-color: #2961f3;
} */

#wallets a .icon-loading {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
}
#wallets a.link:hover {
  opacity: 1;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* #wallets a:hover .icon-loading, */
#wallets a.selected .icon-loading {
  opacity: 1;
  visibility: visible;
  /* animation: spinner 1s linear infinite; */
}
</style>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { MENUS } from '@/constants'
import SmallLoading from '@/components/SmallLoading.vue'

interface Props {
  network: number
  wallet: number
  callback: (type: string, code: string, step: number, account?: any) => void
  connect: (c: any) => void
}

const props = withDefaults(defineProps<Props>(), {
  network: 0,
  wallet: -1,
})

const emit = defineEmits(['close', 'next', 'prev', 'select-wallet'])

const item = ref(null)

const select = (e: any, n: number, w: number) => {
  emit('select-wallet', w)
  item.value = e
  if (e.connect) {
    props.connect(e.connect(props.callback))
  }
}

const menu = computed(() => MENUS[props.network])
</script>
