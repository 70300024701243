<script setup lang="ts">
import type { Amount, MarketPrice, Maps, MarketBidPlus } from '@/env'
import { computed, ref, watch, onMounted } from 'vue'
import {
  USD,
  diffDays,
  leftSettlementPeriod,
  getOptionFundsPlaces,
} from '@/utils'
import { useSearchStore } from '@/stores'
import { MONTHS } from '@/constants'

interface Props {
  price: number
  prices: Amount[]
  pricesLoading: boolean
  date: number
  dates: Amount[]
  datesLoading: boolean
  disabled: boolean
  showBack: boolean
  value: number
  index: number
  amount: string
  show: boolean
  showOrder: boolean
  fetch: (flag: boolean) => Promise<void>
  fetchDates: (price: number) => Promise<void>
}

const props = withDefaults(defineProps<Props>(), {
  price: 0,
  prices: () => [],
})

const emit = defineEmits([
  'update:price',
  'update:date',
  'update:disabled',
  'update:index',
  'update:amount',
  'update:show',
  'update:showOrder',
  'next',
  'prev',
])

const loading = ref(false)
const search = useSearchStore()

const diff = (val: string) => {
  let diff = Math.abs(diffDays(val, new Date()))
  if (diff < 2) {
    diff = 1
  }
  return diff
}

// interest 50 USDT （ 权利金 x 购买数量 ）
const interestValue = ({ option_type, price }: MarketBidPlus) => {
  let s = ''
  const n = getOptionFundsPlaces(option_type)
  s = (1 * Number(price)).toFixed(n)
  return s
}

// Annualized Yield 30%（计算公式：年化收益率=[（权利金收益 / 本金）/ 投资天数] *365 ×100%，投资天数小于1天按一天算，投资天数大于1天小于2天，按1天计算）
const yieldValue = ({
  strike_price,
  expiration_date,
  price,
}: MarketBidPlus) => {
  let s = ''
  const d = diff(expiration_date)
  s = ((Number(price) / Number(strike_price) / d) * 36500).toFixed(2)
  return s
}

const expirationValue = (val: string) => {
  const date = new Date(val)
  return `${date.getDate()} ${MONTHS[date.getMonth()]} ${date.getFullYear()}`
}

const select = (price: number, date: number, index: number) => {
  emit('update:price', price)
  emit('update:date', date)
  emit('update:index', index)
  emit('update:amount', '1')
  emit('update:show', false)
  emit('update:showOrder', true)
}

const list = computed(() => {
  const map = {} as Maps<Maps<MarketBidPlus>>
  props.prices.forEach(({ value }) => {
    const item = props.dates.find(({ id }) => id === value)
    if (item) {
      const { id: strike_price, value: groups } = item

      groups.forEach(
        ({
          value: { expiration_date, bids, option_type },
        }: {
          value: MarketPrice
        }) => {
          if (!map[expiration_date]) {
            map[expiration_date] = {}
          }
          bids.forEach((bid, i) => {
            map[expiration_date][`${strike_price}-${i}`] = {
              index: i,
              option_type,
              strike_price,
              expiration_date,
              ...bid,
            }
          })
        }
      )
    }
  })
  const arr = Object.keys(map)
  arr.sort()
  return arr.map((date) => ({ date, group: map[date] }))
})

const loadData = async (flag = false) => {
  emit('update:price', 0)
  loading.value = true
  await props.fetch(flag)
  if (props.prices.length) {
    try {
      await Promise.all(
        props.prices.map(({ value }) => props.fetchDates(value))
      )
    } catch (err) {
      console.log(err)
    }
  }
  loading.value = false
}

search.$subscribe(() => loadData(true))

onMounted(loadData)
</script>

<template>
  <div class="flex flex-1 overflow-hidden">
    <div class="w-full flex flex-col scroll-container relative">
      <div class="w-250 space-y-10 pb-5">
        <div class="space-y-5" v-for="{ date, group } in list" :key="date">
          <h3 class="font-400 text-base leading-5 space-x-3 flex items-center">
            <span class="text-neutral-310 uppercase">
              {{ expirationValue(date) }}
            </span>
            <span class="text-xss text-neutral-500">|</span>
            <span class="text-white text-opacity-30">
              Wait
              {{ leftSettlementPeriod(date) }}
            </span>
          </h3>
          <div
            class="w-full h-22.5 bg-neutral-550 bg-opacity-6 border-1 border-white border-opacity-6 rounded-4 box-border px-15 action-item flex flex-row justify-between items-center font-400"
            v-for="[key, item] in Object.entries(group)"
            :key="key"
          >
            <div>
              <div class="text-0.8125base text-white text-opacity-30 leading-4">
                Buy When
              </div>
              <div class="text-lg leading-6.5 text-white mt-1">
                {{ USD(item.strike_price).format() }}
              </div>
            </div>
            <div>
              <div class="text-0.8125base text-white text-opacity-30 leading-4">
                Annualized Yield
              </div>
              <div class="text-lg leading-6.5 text-white mt-1">
                {{ yieldValue(item) }}%
              </div>
            </div>
            <div>
              <div class="text-0.8125base text-white text-opacity-30 leading-4">
                Interest
              </div>
              <div class="text-lg leading-6.5 text-white mt-1">
                <!-- {{ USD(interestValue(item)).format() }} -->
                ${{ interestValue(item) }}
              </div>
            </div>
            <div class="ml-30">
              <button
                class="font-800 border-1 border-yellow-550 border-opacity-30 rounded-1.5 text-yellow-550 text-sm0.9 box-border w-32 h-11"
                un-hover="border-opacity-100"
                @click="
                  select(
                    item.strike_price as number,
                    new Date(item.expiration_date).getTime(),
                    item.index
                  )
                "
              >
                BUY
              </button>
            </div>
          </div>
        </div>
      </div>
      <svg
        v-if="loading"
        class="absolute self-center top-20"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19ZM5.017 19C5.017 26.7226 11.2774 32.983 19 32.983C26.7226 32.983 32.983 26.7226 32.983 19C32.983 11.2774 26.7226 5.017 19 5.017C11.2774 5.017 5.017 11.2774 5.017 19Z"
          fill="white"
          fill-opacity="0.23"
        />
        <path
          d="M38 19C38 15.2422 36.8857 11.5687 34.7979 8.44417C32.7102 5.31963 29.7428 2.88435 26.271 1.44629C22.7992 0.00822384 18.9789 -0.36804 15.2933 0.36508C11.6076 1.0982 8.22217 2.90777 5.56497 5.56497L9.11253 9.11253C11.0681 7.15697 13.5596 5.82522 16.2721 5.28568C18.9845 4.74614 21.796 5.02305 24.3511 6.08139C26.9061 7.13973 29.09 8.93197 30.6264 11.2315C32.1629 13.531 32.983 16.2344 32.983 19H38Z"
          fill="white"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 19 19;360 19 19"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  </div>
</template>

<style scoped>
.action-item {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(4px);
}

.action-item button {
  transition: opacity 0.2s ease, border-color 0.2s ease;
}
</style>
