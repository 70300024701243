import Fennec from '@foxone/fennec-dapp'

const ORIGIN = 'OptionDance'

let client: Fennec | null = null

export const createOrder = async (code: string): Promise<boolean> => {
  if (!client) {
    client = new Fennec()
  }

  if (!client.connected) {
    await client.connect(ORIGIN)
  }

  if (client.connected && client.isAvailable()) {
    if (client.ctx) {
      return await client.ctx.wallet.multisigsPayment({ code })
    }
    return false
  }

  return false
}

export default function authorize(
  callback: (type: string, code: string, step: number, account: any) => void
) {
  if (!client) {
    client = new Fennec()
  }

  const p = client.connected ? Promise.resolve() : client.connect(ORIGIN)

  p.then(() => {
    if (client?.connected && client.isAvailable()) {
      return Promise.all([
        // @ts-ignore
        client.ctx?.wallet.signToken({ playload: { from: ORIGIN } }),
        client.ctx?.accounts.current(),
      ])
    }
    return null
  })
    .then((res) => {
      if (Array.isArray(res)) {
        // @ts-ignore
        callback('fennec', res[0], 3, res[1])
      } else {
        callback('fennec', '', -1, null)
      }
    })
    .catch((err) => {
      console.log(err)
      callback('fennec', '', -1, null)
    })

  return client
}
