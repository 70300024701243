/* eslint-disable  */
// @ts-nocheck

import ReconnectingWebSocket from 'reconnecting-websocket'
import pako from 'pako'
import { v4 } from 'uuid'

export interface AuthParams {
  clientId: string
  scope: string
  codeChallenge?: string
}

function MixinClient(api, endpoint) {
  this.api = api
  this.endpoint = endpoint
}

MixinClient.prototype = {
  disconnect() {
    this.ws.close()
  },
  connect() {
    this.ws.reconnect()
  },
  init(callback, clientId, scope, codeChallenge) {
    const self = this

    self.handled = false
    self.callback = callback
    self.ws = new ReconnectingWebSocket(self.endpoint, 'Mixin-OAuth-1', {
      maxReconnectionDelay: 5000,
      minReconnectionDelay: 1000,
      reconnectionDelayGrowFactor: 1.2,
      connectionTimeout: 8000,
      maxRetries: Infinity,
      startClosed: true,
      debug: false
    })
    self.data = null

    self.ws.addEventListener('message', function (event) {
      if (self.handled) {
        return
      }
      const fileReader = new FileReader()
      fileReader.onload = function () {
        const msg = pako.ungzip(new Uint8Array(this.result), { to: 'string' })
        const authorization = JSON.parse(msg)
        if (self.callback(authorization)) {
          self.handled = true
          return
        }
        if (!self.data) {
          self.data = authorization.data
        }
        setTimeout(function () {
          self.sendRefreshCode(clientId, scope, codeChallenge, self.data)
        }, 1000)
      }
      fileReader.readAsArrayBuffer(event.data)
    })

    self.ws.onopen = function (_) {
      self.data = null
      self.sendRefreshCode(clientId, scope, codeChallenge, self.data)
    }
  },

  sendRefreshCode(clientId, scope, codeChallenge, authorization) {
    const self = this
    if (self.handled) {
      return
    }

    self.send({
      id: v4().toUpperCase(),
      action: 'REFRESH_OAUTH_CODE',
      params: {
        client_id: clientId,
        scope,
        code_challenge: codeChallenge,
        authorization_id: authorization ? authorization.authorization_id : ''
      }
    })
  },

  send(msg) {
    try {
      this.ws.send(pako.gzip(JSON.stringify(msg)))
    } catch (e) {
      if (e instanceof DOMException) {
      } else {
        console.error(e)
      }
    }
  }
}


let client = null

export default function authorize(
  params: AuthParams,
  isFiresbox = false,
  // step: -1: error, 0: code, 1: authorization code
  callback: (type: string, code: string, step: number) => void
) {
  if (!client) {
    const [http, ws] = isFiresbox
      ? ['https://xuexi-api.firesbox.com', 'wss://xuexi-blaze.firesbox.com']
      : ['https://api.mixin.one', 'wss://blaze.mixin.one']
    client = new MixinClient(http, ws)

    const handler = (resp) => {
      const data = resp.data

      if (resp?.error?.code === 400 || resp?.error?.code === 10002) {
        callback('mixin', resp?.error, -1)

        return true
      }

      if (!data) return false

      if (data.authorization_code.length > 16) {
        callback('mixin', data.authorization_code, 1)

        return true
      }

      callback('mixin', data.code_id, 0)
      return false
    }

    client.init(
      handler,
      params.clientId,
      params.scope,
      params?.codeChallenge ?? ''
    )
  }

  client.connect()

  return client
}
