<script setup lang="ts">
import type { Amount } from '@/env'
import { computed, onActivated, watch, ref } from 'vue'

interface Props {
  type: string
  title: string
  loading: boolean
  value: number
  cachedList: Amount[]
  order?: boolean
  format: (value: any) => string
  get: (value: any) => number
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  order: false,
})

const emit = defineEmits(['update:value'])

const index = ref(-1)
const arrow = ref(true)
const list = ref<Amount[]>([])

const up = (n: number) => {
  if (props.cachedList.length === 0 || n === props.cachedList.length - 1) {
    index.value = n
    return
  }

  arrow.value = true
  n += 1
  index.value = n
  const item = props.cachedList[n]
  list.value.unshift(item)

  emit('update:value', props.get(item.value))

  if (list.value.length === 1) {
    return
  }

  list.value.pop()
}
const down = (n: number) => {
  if (n === 0) {
    return
  }

  arrow.value = false
  n -= 1
  index.value = n

  list.value.shift()
  const item = props.cachedList[n]
  list.value.push(item)

  emit('update:value', props.get(item.value))
}

const name = computed(() => `slide-fade-${arrow.value ? 'in' : 'out'}`)
const disabledUp = computed(
  () => index.value === -1 || index.value === props.cachedList.length - 1
)
const disabledDown = computed(() => index.value < 1)

watch(
  () => props.cachedList,
  (a) => {
    if (a.length === 0) {
      list.value = []
    }
    up(-1)
  }
)

onActivated(() => {
  let i = props.cachedList.findIndex((v) => props.value === props.get(v.value))
  if (i !== -1) {
    if (list.value.length === 0) {
      up(-1)
    } else if (index.value !== i) {
      up(--i)
    }
  }
})
</script>

<template>
  <div class="absolute top-18 action-card flex justify-center select-none">
    <svg
      width="470"
      height="493"
      viewBox="0 0 470 493"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute"
    >
      <!-- <g filter="url(#filter1_d_5390_41768)"> -->
      <g>
        <path
          d="M91.3729 136.098C92.3862 115.876 109.078 100 129.325 100H340.675C360.922 100 377.614 115.876 378.627 136.098L389 343.098C390.088 364.805 372.782 383 351.048 383H118.952C97.2182 383 79.9118 364.805 80.9996 343.098L91.3729 136.098Z"
          fill="#241414"
        />
        <path
          d="M92.3716 136.148C93.3583 116.458 109.61 101 129.325 101H340.675C360.39 101 376.642 116.458 377.628 136.148L388.002 343.148C389.061 364.284 372.21 382 351.048 382H118.952C97.7901 382 80.9392 364.283 81.9983 343.148L92.3716 136.148Z"
          stroke="url(#paint1_linear_5390_41768)"
          stroke-width="2"
        />
      </g>
    </svg>
    <div
      class="absolute action-card-ellipse"
      :class="{
        [`${type}`]: true,
      }"
    />
    <svg
      width="470"
      height="493"
      viewBox="0 0 470 493"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="absolute"
    >
      <text
        x="50%"
        y="166"
        fill="white"
        opacity="0.8"
        font-weight="700"
        font-size="16px"
        text-anchor="middle"
        alignment-baseline="middle"
      >
        {{ title }}
      </text>
      <template v-if="!loading">
        <transition-group v-if="list.length" :name="name">
          <g class="action-card-value" v-for="item in list" :key="item.id">
            <text
              x="50%"
              y="246"
              fill="white"
              font-weight="800"
              font-size="46px"
              text-anchor="middle"
              alignment-baseline="middle"
            >
              {{ format(item.value) }}
            </text>
          </g>
        </transition-group>
        <g class="action-card-value" v-else>
          <text
            x="50%"
            y="246"
            fill="rgba(255, 255, 255, 0.6)"
            font-weight="800"
            font-size="24px"
            text-anchor="middle"
            alignment-baseline="middle"
          >
            SOLD OUT
          </text>
        </g>
      </template>
      <svg
        x="122"
        y="316"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
        class="action-op cursor-pointer"
        :aria-disabled="order ? disabledUp : disabledDown"
        @click="order ? up(index) : down(index)"
      >
        <path
          transform-origin="18 18"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 18C6 17.1716 6.70077 16.5 7.56522 16.5H28.4348C29.2992 16.5 30 17.1716 30 18C30 18.8284 29.2992 19.5 28.4348 19.5H7.56522C6.70077 19.5 6 18.8284 6 18Z"
          fill="white"
        />
        <rect width="36" height="36" />
      </svg>

      <svg
        x="310"
        y="316"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="transparent"
        xmlns="http://www.w3.org/2000/svg"
        class="action-op cursor-pointer"
        :aria-disabled="order ? disabledDown : disabledUp"
        @click="order ? down(index) : up(index)"
      >
        <path
          transform-origin="18 18"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.0018 28.0749C17.0432 28.9023 17.7475 29.5395 18.5749 29.4982C19.4023 29.4568 20.0395 28.7525 19.9981 27.9251L19.5768 19.5H27.5C28.3284 19.5 29 18.8284 29 18C29 17.1716 28.3284 16.5 27.5 16.5H19.4268L18.9981 7.92513C18.9567 7.09773 18.2525 6.46054 17.4251 6.50191C16.5977 6.54328 15.9605 7.24755 16.0018 8.07494L16.4231 16.5H7.5C6.67157 16.5 6 17.1716 6 18C6 18.8284 6.67157 19.5 7.5 19.5H16.5731L17.0018 28.0749Z"
          fill="white"
        />
        <rect width="36" height="36" />
      </svg>

      <svg
        v-if="loading"
        x="217"
        y="222"
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M38 19C38 29.4934 29.4934 38 19 38C8.50659 38 0 29.4934 0 19C0 8.50659 8.50659 0 19 0C29.4934 0 38 8.50659 38 19ZM5.017 19C5.017 26.7226 11.2774 32.983 19 32.983C26.7226 32.983 32.983 26.7226 32.983 19C32.983 11.2774 26.7226 5.017 19 5.017C11.2774 5.017 5.017 11.2774 5.017 19Z"
          fill="white"
          fill-opacity="0.23"
        />
        <path
          d="M38 19C38 15.2422 36.8857 11.5687 34.7979 8.44417C32.7102 5.31963 29.7428 2.88435 26.271 1.44629C22.7992 0.00822384 18.9789 -0.36804 15.2933 0.36508C11.6076 1.0982 8.22217 2.90777 5.56497 5.56497L9.11253 9.11253C11.0681 7.15697 13.5596 5.82522 16.2721 5.28568C18.9845 4.74614 21.796 5.02305 24.3511 6.08139C26.9061 7.13973 29.09 8.93197 30.6264 11.2315C32.1629 13.531 32.983 16.2344 32.983 19H38Z"
          fill="white"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            calcMode="linear"
            values="0 19 19;360 19 19"
            keyTimes="0;1"
            dur="1s"
            begin="0s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </svg>
  </div>
</template>

<style>
.action-card {
  appearance: none;
  width: 470px;
  height: 493px;
}
.action-card-ellipse {
  top: 100px;
  content: '';
  position: absolute;
  width: 249px;
  height: 254px;
  /* opacity: 0.6; */
  filter: blur(60px);
  appearance: none;
}

.action-card-ellipse.dh {
  background: radial-gradient(
    62.72% 62.72% at 50% 37.28%,
    #ff6f61 0.26%,
    rgba(255, 111, 97, 0.496066) 72.4%,
    rgba(255, 111, 97, 0) 100%
  );
}

.action-card-ellipse.sp {
  background: radial-gradient(
    62.72% 62.72% at 50% 37.28%,
    #f17e13 0.26%,
    rgba(240, 127, 16, 0.5) 72.4%,
    rgba(240, 127, 15, 0) 100%
  );
}

.action-card-ellipse.cp {
  background: radial-gradient(
    62.72% 62.72% at 50% 37.28%,
    #4ec3c8 0.26%,
    rgba(78, 195, 200, 0.496066) 72.4%,
    rgba(78, 195, 200, 0) 100%
  );
}

.action-card-ellipse.bp {
  background: radial-gradient(
    62.72% 62.72% at 50% 37.28%,
    #60baf8 0.26%,
    rgba(96, 186, 248, 0.496066) 72.4%,
    rgba(96, 186, 248, 0) 100%
  );
}

.action-card-value {
  transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1),
    opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  will-change: transform, opacity;
  appearance: none;
}
.action-card-value.slide-fade-in-enter-from,
.action-card-value.slide-fade-out-leave-to {
  transform: translate3d(0, -30px, 0);
  opacity: 0;
}
.action-card-value.slide-fade-in-leave-to,
.action-card-value.slide-fade-out-enter-from {
  transform: translate3d(0, 62px, 0);
  opacity: 0;
}

.action-card .action-op {
  transition: transform 0.2s ease;
  will-change: transform;
  appearance: none;
}
.action-card .action-op path {
  transition: transform 0.2s ease;
}
.action-card .action-op:not([aria-disabled='true']):hover path {
  transform: scale(1.2173);
}

.action-card .action-op[aria-disabled='true'] {
  pointer-events: none;
  opacity: 0.3;
}
</style>
