<script setup lang="ts">
import { ref } from 'vue'

const d = ref()

const close = () => {
  d.value.classList.remove('show')
}
</script>

<template>
  <div
    ref="d"
    class="flex flex-row items-center px-8 py-4 bg-dark-500 backdrop-blur-40 rounded-2.5xl"
  >
    <div class="flex items-center justify-center h-12 w-12">
      <SvgIcon name="construction" width="36" height="39" />
    </div>
    <div class="ml-4 flex flex-col justify-between">
      <h4 class="font-700 text-lg text-white">Under construction</h4>
      <p class="font-600 text-xs text-neutral-450">
        This feature is under development and will be online soon
      </p>
    </div>

    <button
      class="absolute top-4 right-8 flex items-center justify-center h-5 w-5 text-neutral-500 btn-close"
      un-hover="text-white"
      @click="close"
    >
      <svg-icon name="close" width="8.5" height="8.5" />
    </button>
  </div>
</template>
