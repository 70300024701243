//https://v2.dinerojs.com/docs/getting-started/optimizing-for-production
//https://currency.js.org/
import type { Maps } from '@/env'
import currency from 'currency.js'

// https://github.com/scurker/currency.js/issues/299
function currencyFormater(
  currency: string | number,
  settings: currency.Options
) {
  // @ts-ignore
  let { pattern, negativePattern, symbol, separator, decimal, groups } =
    settings,
    split = ('' + currency).replace(/^-/, '').split('.'),
    dollars = split[0],
    cents = split[1]
  if (parseInt(cents) == 0) cents = ''
  return (
    // @ts-ignore
    (currency.value >= 0 ? pattern : negativePattern)
      // @ts-ignore
      .replace('!', symbol)
      .replace(
        '#',
        dollars.replace(groups, '$1' + separator) +
        (cents ? decimal + cents : '')
      )
  )
}

export const USD = (value: string | number) =>
  currency(value, {
    symbol: '$',
    precision: 1,
    // @ts-ignore
    format: currencyFormater,
  })

export const toNumber = (s: string, min: number, max: number): number => {
  const n = Number(s)
  return Number.isNaN(n) ? min : Math.max(Math.min(n, max), min)
}

export const getType = (side: string, type: string): string => {
  const sell = side === 'ASK'
  const put = type === 'PUT'

  // sell-put
  if (sell && put) return 'dh'

  const call = !put && type === 'CALL'

  // sell-call
  if (sell && call) return 'sp'

  const buy = !sell && side === 'BID'

  // buy-put
  if (buy && put) return 'cp'

  // buy-call
  if (buy && call) return 'bp'

  // history
  return 'hc'
}

export function parseInstrumentName(name: string) {
  let idxDeliveryType = 1
  let idxQuoteCurrency = 2
  let idxBaseCurrency = 3
  let idxDay = 4
  let idxMonth = 5
  let idxYear = 6
  let idxStrikePrice = 7
  let idxOptionType = 8

  let instrument = {}
  // let monthMap = { "JAN": "01", "FEB": "02", "MAR": "03", "APR": "04", "MAY": "05", "JUN": "06", "JUL": "07", "AUG": "08", "SEP": "09", "OCT": "10", "NOV": "11", "DEC": "12" }
  const reg =
    /^([CP])-(pUSD|USDC|USDT|BTC|ETH)-(BTC|XIN|ETH)-([\d]{1,2})(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)([\d]{2})-([\d]+)-([CP])$/
  const match = name.match(reg)

  if (!match) {
    return null
  }

  let day = match[idxDay]
  if (day.length === 1) {
    day = '0' + day
  }
  let timeString = `20${match[idxYear]}-${match[idxMonth]}-${day}T16:00:00+08:00`
  let expirationDate = new Date(timeString)
  let strikePrice = match[idxStrikePrice]
  let deliveryType = ''
  if (match[idxDeliveryType] === 'P') {
    deliveryType = 'PHYSICAL'
  }
  if (match[idxDeliveryType] === 'C') {
    deliveryType = 'CASH'
  }
  let optionType = ''
  if (match[idxOptionType] === 'P') {
    optionType = 'PUT'
  }
  if (match[idxOptionType] === 'C') {
    optionType = 'CALL'
  }
  if (match.length === 9) {
    instrument = {
      deliveryType: deliveryType,
      quoteCurrency: match[idxQuoteCurrency],
      baseCurrency: match[idxBaseCurrency],
      expirationDate: expirationDate,
      expirationTimestamp: expirationDate.getTime(),
      strikePrice: strikePrice,
      optionType: optionType,
    }
  }
  return instrument
}

export const formatDate = function (date: string, fmt: string): string {
  const d = new Date(date)
  const o = {
    'M+': d.getMonth() + 1, //月份
    'd+': d.getDate(), //日
    'h+': d.getHours(), //小时
    'm+': d.getMinutes(), //分
    's+': d.getSeconds(), //秒
    'q+': Math.floor((d.getMonth() + 3) / 3), //季度
    S: d.getMilliseconds(), //毫秒
  } as Maps<number>
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (d.getFullYear() + '').substr(4 - RegExp.$1.length),
    )
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1
          ? String(o[k])
          : ('00' + String(o[k])).substr(('' + String(o[k])).length)
      )
    }
  }
  return fmt
}

export function diffDays(
  newer: string | number | Date,
  older: string | number | Date
) {
  const newerDate = new Date(newer)
  const olderDate = new Date(older)
  const differenceInTime = newerDate.getTime() - olderDate.getTime()
  const day = parseInt((differenceInTime / (1000 * 3600 * 24)).toFixed(2))
  return day === 0 ? 1 : day
}

export function leftSettlementPeriod(
  expirationDate: string | number | undefined
) {
  if (expirationDate) {
    const now = new Date()
    const nowTs = now.getTime()
    const expiry = new Date(expirationDate)
    const expiryTs = expiry.getTime()
    const differenceInTime = expiryTs - nowTs
    const day = diffDays(expiry, now)
    if (day === 0) {
      const hour = parseInt((differenceInTime / (1000 * 3600)).toFixed(2))
      return `${hour} Hours`
    }
    return `${day} Days`
  } else {
    return '0 Days'
  }
}

export function getOptionFundsPlaces(optionType: string) {
  if (optionType === 'CALL') {
    return 5
  } else {
    return 3
  }
}
