<script setup lang="ts">
import { useToast, POSITION } from 'vue-toastification'
import StableProfit from '@/components/home/StableProfit.vue'
import DipHunter from '@/components/home/DipHunter.vue'
import CrashProtection from '@/components/home/CrashProtection.vue'
import BullishProtection from '@/components/home/BullishProtection.vue'
import ErrorIcon from '@/components/toasts/ErrorIcon.vue'
import ErrorToast from '@/components/toasts/ErrorToast.vue'
import CloseIcon from '@/components/toasts/CloseIcon.vue'

const toast = useToast()

const disable = () => {
  toast.error(
    {
      type: 'error',
      component: ErrorToast,
      props: {
        text: 'This feature is under development',
      },
    },
    {
      // timeout: 10000,
      timeout: false,
      icon: ErrorIcon,
      closeButton: CloseIcon,
      position: POSITION.TOP_CENTER,
      hideProgressBar: true,
    }
  )
}
</script>

<template>
  <div class="flex flex-1 flex-row justify-around items-center">
    <router-link
      to="/dip-hunter"
      class="flex flex-col items-center cursor-pointer w-55 action"
    >
      <dip-hunter />
      <h3
        class="text-white text-2xl0.9375 font-900 transition-text-shadow mt--9"
      >
        Dip Hunter
      </h3>
      <p class="text-neutral-500 text-xs1.125 font-500 text-center mt-3.5">
        Price up earns dollar, price <br />
        down earns coin
      </p>
    </router-link>
    <router-link
      to="/crash-protection"
      class="flex flex-col items-center cursor-pointer w-55 action"
    >
      <crash-protection />
      <h3
        class="text-white text-2xl0.9375 font-900 transition-text-shadow mt--9"
      >
        Crash Protection
      </h3>
      <p class="text-neutral-500 text-xs1.125 font-500 text-center mt-3.5">
        Have a good night's sleep <br />
        without fear of losing money
      </p>
    </router-link>
    <router-link
      to="/bullish-protection"
      class="flex flex-col items-center cursor-pointer w-55 action"
    >
      <bullish-protection />
      <h3
        class="text-white text-2xl0.9375 font-900 transition-text-shadow mt--9"
      >
        Bullish Protection
      </h3>
      <p class="text-neutral-500 text-xs1.125 font-500 text-center mt-3.5">
        Price to the moon, buy <br />
        at a discount
      </p>
    </router-link>
    <router-link
      to="/stable-profit"
      class="flex flex-col items-center cursor-pointer w-55 action"
    >
      <stable-profit />
      <h3
        class="text-white text-2xl0.9375 font-900 transition-text-shadow mt--9"
      >
        Stable Profit
      </h3>
      <p class="text-neutral-500 text-xs1.125 font-500 text-center mt-3.5">
        Lock the profit, coin <br />
        begets money
      </p>
    </router-link>

    <footer
      class="absolute bottom-0 right-0 left-0 h-12 bg-white bg-opacity-2 flex items-center justify-center box-border"
    >
      <p class="font-400 text-base0.833 text-neutral-500">
        We're working nearly 24/7 to make this thing awesome. Feel free to say
        hello to us.
      </p>
    </footer>
  </div>
</template>

<style>
.action:hover h3 {
  text-shadow: 0px 4px 20px rgba(255, 255, 255, 0.2);
}
.action:hover svg .halo {
  opacity: 1;
}
</style>
