<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    type: string
    size?: number
    grayscale?: boolean
  }>(),
  {
    size: 38,
    type: 'dh',
    grayscale: false,
  }
)

const className = computed(() => {
  let c = props.grayscale ? 'text-white ' : ''
  switch (props.type) {
    case 'dh':
      c += 'quarter-dh'
      break
    case 'cp':
      c += 'quarter-cp'
      break
    case 'bp':
      c += 'quarter-bp'
      break
    case 'sp':
      c += 'quarter-sp'
      break
    default:
      c += 'quarter-hc'
      break
  }
  return c
})
</script>

<template>
  <svg-icon
    :width="size"
    :height="size"
    :class="className"
    :name="`small-${type === 'hc' ? 'logo' : 'quarter'}`"
  />
</template>
