<script setup lang="ts">
import type { Amount } from '@/env'
import { onUnmounted, onMounted } from 'vue'

interface Props {
  list: Amount[]
  value: number
  format: (value: number) => string
  get: (value: any) => any
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits(['close', 'update:value'])

const select = (index: number) => {
  emit('update:value', props.get(props.list[index].value))
  emit('close')
}

const click = (e: any) => {
  if (e.target.classList.contains('dropdown-target')) {
    return true
  }
  if (!e.target.parentNode.classList.contains('dropdown')) {
    emit('close')
  }
}

onMounted(() => {
  document.body.addEventListener('click', click, false)
})
onUnmounted(() => {
  document.body.removeEventListener('click', click)
})
</script>

<template>
  <div
    class="absolute z-1024 bg-stone-903 left-0 right-0 rounded-3 py-3.5 dropdown"
  >
    <ul class="text-lg leading-6 font-400 text-neutral-500">
      <li
        v-for="(item, index) in list"
        :key="item.id"
        :data-value="item.value"
        @click.self.stop="select(index)"
        class="cursor-pointer h-11 px-5 flex items-center"
        un-hover="text-white"
      >
        {{ format(item.value) }}
      </li>
    </ul>
  </div>
</template>
