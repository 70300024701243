import type { OAuthResult, Result, AuthResult } from '@/env'
import { API_HOST } from './constants'

function checkStatus(response: Response) {
  return new Promise((resolve, reject) => {
    const json = response.json()

    // [200,299]
    if (response.ok) {
      return json.then(({ code, data, msg }: Result) =>
        code === 0
          ? resolve(data)
          : reject({
            code,
            msg
          })
      )
    }

    json.then(reject)
  })
}

function client(input: string, init?: RequestInit, v = 1) {
  if (!init) {
    init = {}
  }

  init.headers = {
    ...init.headers,
    'Content-Type': 'application/json',
  }

  return fetch(`${API_HOST}/api/v${v}/${input}`, init)
    .then(checkStatus)
    .catch((err) => {
      console.error(err)
      throw err
    })
}

// Fetch Mixin token with code
const getOAuthToken = (code: string) =>
  post('oauth', {
    body: JSON.stringify({
      code,
      // client_id: MIXIN_CLIENT_ID
    }),
  }) as Promise<OAuthResult>

const getToken = (token: string) =>
  post('auth', {
    body: JSON.stringify({
      token,
    }),
  }) as Promise<AuthResult>

const get = (path: string, init?: RequestInit, v = 1) => client(path, init, v)

const post = (path: string, init?: RequestInit) =>
  client(path, { ...init, method: 'POST' })

const getStrikePrices = (search: URLSearchParams) =>
  get(`market/strike-prices?${search.toString()}`)

const getExpiryDatesByPrice = (strikePrice: number, search: URLSearchParams) =>
  get(`market/price/${strikePrice}?${search.toString()}`)

export default {
  client,
  get,
  post,
  getOAuthToken,
  getToken,
  getStrikePrices,
  getExpiryDatesByPrice,
}
