<script setup lang="ts">
import { useSearchStore } from '@/stores'
import { MARKETS } from '@/constants'

const search = useSearchStore()
</script>

<template>
  <div class="pt-5">
    <div class="relative bg-neutral-1001 rounded-2.5xl inset-border p-3.5">
      <h3 class="px-3 py-2 text-sm0.9 text-neutral-500 font-400">Markets</h3>
      <div class="flex flex-col">
        <button
          class="flex items-center px-5 py-4 border border-white border-opacity-5 bg-neutral-810 bg-opacity-50 rounded-2.5 mt-3"
          v-for="market in MARKETS"
          :key="market"
          :disabled="search.baseCurrency === market"
          :class="{
            selected: search.baseCurrency === market,
          }"
          @click="search.setMarket(market)"
        >
          <svg-icon :name="`coins-${market}-24`" width="24" height="24" />
          <span
            class="inline-block mx-3.5 bg-neutral-500 bg-opacity-30 h-3.5 w-1px"
          />
          <span class="text-sm0.9 text-white font-700 tracking-0.06em">
            {{ market }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#markets {
  & > .menu {
    opacity: 0;
    transform: translateY(-4px);
    transition: opacity 0.2s ease, visibility 0.2s ease, transform 0.2s ease;

    button {
      &:hover,
      &.selected {
        background: #2e2e2e;
        border-color: rgba(255, 255, 255, 0.1);
        box-shadow: inset 0px 3px 46px rgba(0, 0, 0, 0.1);
      }
    }
  }

  &:hover > .menu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
</style>
